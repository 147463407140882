import { Button } from 'components/common/Button/Button';
import { Radio } from 'components/common/Radio/Radio';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { useLineChartURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { PinchAndZoomIcon } from 'icons/PinchAndZoomIcon';
import { ComponentProps, forwardRef, useMemo } from 'react';
import {
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { SeriesToggle } from './SeriesToggle';

const { AVERAGE, CONTINUOUS } = MeasurementAggregation;

const viewTypeTooltip = `Continuous line chart is only enabled for time frame windows less than or equal to 7 days`;

interface ToolbarProps extends ComponentProps<'div'> {
  chart: Highcharts.Chart;
  disabledViewType: boolean;
  showResetZoom: boolean;
  signals: MeasurementTypeConfig[];
  onToggleSeries: ComponentProps<typeof SeriesToggle>['onToggleSeries'];
}

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  function Toolbar(
    {
      className,
      chart,
      disabledViewType,
      showResetZoom,
      signals,
      onToggleSeries,
      ...props
    },
    ref
  ) {
    const { viewType, setViewType } = useLineChartURL();
    const multipleAggregationsAvailable = useMemo(
      () => signals.every((signal) => signal.hasMultipleAggregations),
      [signals]
    );

    return (
      <div
        {...props}
        ref={ref}
        className={cn('flex gap-2 flex-wrap items-center', className)}
      >
        <SeriesToggle signals={signals} onToggleSeries={onToggleSeries} />

        <div className="relative ml-auto flex gap-2">
          {multipleAggregationsAvailable && (
            <Tooltip label={viewTypeTooltip} withArrow>
              <Radio.Group
                value={viewType}
                orientation="horizontal"
                disabled={disabledViewType}
                onChange={(e) =>
                  setViewType(e.target.value as MeasurementAggregation)
                }
              >
                <Radio.Input
                  labelClassName="text-xs"
                  value={AVERAGE}
                  label={AVERAGE}
                />
                <Radio.Input
                  labelClassName="text-xs"
                  value={CONTINUOUS}
                  label={CONTINUOUS}
                />
              </Radio.Group>
            </Tooltip>
          )}

          <div id="slot-chart-filters" />

          <div className="highcharts-bindings-container absolute top-14 right-0 flex gap-2 z-10">
            {showResetZoom && (
              <Button
                size="responsive"
                variant="secondary"
                className="shadow font-normal text-xs"
                leadingIcon={<PinchAndZoomIcon />}
                onClick={() => chart.zoomOut()}
              >
                Reset Zoom
              </Button>
            )}

            <div id="slot-chart-tools" />
          </div>
        </div>
      </div>
    );
  }
);
