import { Button, ButtonProps } from 'components/common/Button/Button';
import {
  Checkbox,
  CheckboxInputProps,
} from 'components/common/Checkbox/Checkbox';
import { Modal } from 'components/common/Modal/Modal';
import { useDisclosure } from 'hooks/useDisclosure';
import { useScreenSize } from 'hooks/useScreenSize';
import { ChevronDownIcon } from 'icons/ChevronDownIcon';
import {
  ComponentProps,
  forwardRef,
  Fragment,
  useCallback,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

interface SidebarProps extends ComponentProps<'div'> {
  signalIds: string[];
  groups: [EMeasurementGroup, MeasurementTypeConfig[]][];
  updateSignalIds: (ids: string[]) => void;
}

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  function Sidebar(
    { className, signalIds, groups, updateSignalIds, ...props },
    ref
  ) {
    const { isMobile } = useScreenSize();
    const [mobileSignalIds, setMobileSignalIds] = useState(signalIds);
    const disclosure = useDisclosure({
      onOpen: () => {
        setMobileSignalIds(signalIds);
      },
      onClose: () => {
        setMobileSignalIds(signalIds);
      },
    });
    const handleCheckSignal =
      (typeConfig: MeasurementTypeConfig): CheckboxInputProps['onChange'] =>
      (event) => {
        const { type } = typeConfig;

        if (!event.target.checked && signalIds.length === 1) {
          return;
        } else {
          updateSignalIds(
            !event.target.checked
              ? signalIds.filter((id) => id !== type)
              : [...signalIds, type]
          );
        }
      };
    const handleCheckSignalMobile =
      (typeConfig: MeasurementTypeConfig): ButtonProps['onClick'] =>
      () => {
        const { type } = typeConfig;

        setMobileSignalIds((prev) => {
          if (prev.length === 1 && prev.includes(type)) {
            return prev;
          }

          return prev.includes(type)
            ? prev.filter((id) => id !== type)
            : [...prev, type];
        });
      };
    const handleConfirmSignals = useCallback(() => {
      updateSignalIds(mobileSignalIds);
      disclosure.close();
    }, [disclosure, mobileSignalIds, updateSignalIds]);

    return (
      <>
        {!isMobile && (
          <div
            ref={ref}
            {...props}
            className={cn(
              'flex flex-col gap-2 overflow-y-auto scrollbar-thin px-2',
              className
            )}
          >
            {groups.map(([group, types]) => (
              <div key={group} className="flex flex-col gap-1">
                <h2 className="font-semibold">{group}</h2>
                <hr className="h-px" />
                {types.map((type, index) => {
                  const previous = types[index - 1];
                  const includeSubgroup =
                    type.subgroup && previous?.subgroup !== type.subgroup;

                  return (
                    <Fragment key={`${type.subgroup} ${type.label}`}>
                      {includeSubgroup && (
                        <h3 className="font-medium text-sm pt-1">
                          {type.subgroup}
                        </h3>
                      )}
                      <Checkbox.Input
                        label={
                          <div className="flex gap-1 items-center">
                            <div
                              className={cn(
                                'w-3 h-3 rounded-full',
                                type.style?.html
                              )}
                            />
                            {type.label}
                          </div>
                        }
                        name={type.type}
                        checked={signalIds.includes(type.type)}
                        labelPlacement="right"
                        onChange={handleCheckSignal(type)}
                      />
                    </Fragment>
                  );
                })}
              </div>
            ))}
          </div>
        )}

        {isMobile &&
          createPortal(
            <>
              <div className="min-w-fit flex flex-row gap-2">
                {groups.map(([group, types]) => {
                  const selected = types.some(({ type }) =>
                    signalIds.includes(type)
                  );

                  const filterLabel = (() => {
                    const selectedSignals = types.filter(({ type }) =>
                      signalIds.includes(type)
                    );
                    const [firstSignal] = selectedSignals;
                    if (!selected || !firstSignal) {
                      return group;
                    }

                    return selectedSignals.length === 1
                      ? firstSignal.label
                      : `${firstSignal.label} + ${selectedSignals.length - 1}`;
                  })();

                  return (
                    <Button
                      key={group}
                      trailingIcon={<ChevronDownIcon />}
                      className="select-none font-normal text-xs"
                      variant="secondary"
                      selected={selected}
                      onClick={disclosure.open}
                    >
                      {filterLabel}
                    </Button>
                  );
                })}
              </div>
              <Modal open={disclosure.isOpen}>
                <Modal.Header
                  title="Signal Sources"
                  closeButtonProps={{ onClick: disclosure.close }}
                ></Modal.Header>
                <Modal.Content>
                  <div className="flex flex-col gap-4">
                    {groups.map(([group, types]) => (
                      <div key={group} className="flex flex-col gap-2">
                        <h2 className="font-semibold">{group}</h2>

                        <div className="flex flex-wrap gap-2">
                          {types.map((type, index) => {
                            const previous = types[index - 1];
                            const includeSubgroup =
                              type.subgroup &&
                              previous?.subgroup !== type.subgroup;

                            return (
                              <Fragment key={`${type.subgroup} ${type.label}`}>
                                {includeSubgroup && (
                                  <h3 className="font-medium text-sm basis-full">
                                    {type.subgroup}
                                  </h3>
                                )}
                                <Button
                                  key={type.type}
                                  role="radio"
                                  variant="secondary"
                                  selected={mobileSignalIds.includes(type.type)}
                                  onClick={handleCheckSignalMobile(type)}
                                  className="select-none font-normal text-xs"
                                >
                                  {type.label}
                                </Button>
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Content>
                <Modal.Footer>
                  <Button className="w-full" onClick={handleConfirmSignals}>
                    Select Signals
                  </Button>
                </Modal.Footer>
              </Modal>
            </>,
            document.getElementById('filters-slot')!
          )}
      </>
    );
  }
);
