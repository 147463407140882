import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import {
  Chart,
  ChartProps,
  getPlotBands,
  getPolygonSeriesAndZones,
  getZonedPointsAndZones,
} from 'components/common/Chart/Chart';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  differenceInDays,
  endOfDay,
  hoursToMilliseconds,
  startOfDay,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useSignals } from 'hooks/useSignals';
import isNil from 'lodash.isnil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import {
  EMeasurementGroup,
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { getLighCyclesRanges } from 'shared/utils/getters';
import { Annotations } from './Annotations';
import { Sidebar } from './Sidebar';
import { Toolbar } from './Toolbar';
import { useChartHints } from './hooks/useChartHints';

const exceedsMaxAllowedWindow = (start: number, end: number) =>
  start && end ? differenceInDays(end, start) > 7 : false;

const { Environmental } = EMeasurementGroup;

const { AVERAGE } = MeasurementAggregation;

const LineChart = ({
  zone,
  startTime,
  endTime,
  disabledViewType,
}: {
  zone: TZone;
  startTime: number;
  endTime: number;
  disabledViewType: boolean;
}) => {
  const [chart, setChart] = useState<Highcharts.Chart>();
  const { currentCycle: growthCycle } = useGrowthCycles();
  const { viewType } = useLineChartURL();
  const start = useMemo(() => new Date(startTime), [startTime]);
  const end = useMemo(() => new Date(endTime), [endTime]);
  const timelineDates = useMemo(() => [start, end], [start, end]);
  const [timeRange, setTimeRange] = useState<TTimeRange>({
    start: startOfDay(start),
    end: endOfDay(end),
  });
  const { signals, groups, signalIds, updateSignalIds } = useSignals();
  const measurementsQuery = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start,
    end,
    signals,
    aggregation: viewType,
  });
  const showResetZoom =
    start.valueOf() < timeRange.start.valueOf() ||
    end.valueOf() > timeRange.end.valueOf();
  const isSingle = signals.length === 1;
  const ranges = useMemo(
    function computeRanges() {
      return getLighCyclesRanges({
        start,
        end,
        lightInfo: growthCycle?.metadata.light_info ?? [],
      });
    },
    [end, growthCycle?.metadata.light_info, start]
  );
  const plotBands = useMemo(
    function computePlotBands() {
      return getPlotBands(ranges);
    },
    [ranges]
  );
  const { polygonSeries, warningRanges } = useMemo(
    function computeOptimalAndWarningRanges() {
      if (
        !isSingle ||
        !growthCycle ||
        isNil(start) ||
        isNil(end) ||
        measurementsQuery.loading ||
        !measurementsQuery.called
      ) {
        return {
          polygonSeries: [],
          warningRanges: [],
        };
      }

      const { polygonSeries = [], warningRanges } = getPolygonSeriesAndZones({
        end,
        growthCycle,
        start,
        ranges,
        signal: signals[0]!,
      });

      return {
        polygonSeries,
        warningRanges,
      };
    },
    [
      isSingle,
      end,
      growthCycle,
      measurementsQuery.called,
      measurementsQuery.loading,
      ranges,
      signals,
      start,
    ]
  );
  const yAxis = useMemo(
    function computeYAxis() {
      return signals.reduce<Highcharts.YAxisOptions[]>((axes, signal) => {
        const exists = axes.some(({ id }) => id === signal.unit);

        if (!exists) {
          axes.push({
            id: signal.unit,
            reversed: signal.reversedYAxis,
            visible: isSingle,
            title: { text: signal.unit },
          });
        }

        return axes;
      }, []);
    },
    [isSingle, signals]
  );
  const lineSeries = useMemo<Highcharts.SeriesLineOptions[]>(
    function computeLineSeries() {
      const lineSeries: Highcharts.SeriesLineOptions[] = [];
      for (const [signal, data] of measurementsQuery.data) {
        const series: Highcharts.SeriesLineOptions = {
          id: signal.type,
          data,
          name: `${signal.label}${signal.subgroup ? ` (${signal.subgroup}) ` : ''}`,
          type: 'line',
          enableMouseTracking: true,
          states: {
            hover: { enabled: true },
          },
          className: signal.style?.svg,
          yAxis: signal.unit,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ` ${signal.unit}`,
          },
        };

        if (signal.group === Environmental && isSingle) {
          const { points, zones } = getZonedPointsAndZones({
            data,
            warningRanges,
          });

          series.data = points;
          series.zoneAxis = 'x';
          series.zones = zones;
        }

        lineSeries.push(series);
      }

      return lineSeries;
    },
    [isSingle, measurementsQuery.data, warningRanges]
  );
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      if (measurementsQuery.loading || !measurementsQuery.called) {
        return undefined;
      }

      return {
        accessibility: {
          description: `Line chart for ${signals.map(({ label }) => label).join()}`,
        },
        chart: {
          // marginTop: 0,
          spacingTop: 0,
          spacingBottom: 0,
          ...(lineSeries.length > 0
            ? {
                panKey: 'shift',
                panning: { enabled: true, type: 'x' },
                zooming: {
                  type: 'x',
                  pinchType: 'x',
                  mouseWheel: { sensitivity: 2 },
                },
              }
            : {}),
        },
        tooltip: {
          enabled: true,
          split: true,
        },
        xAxis: {
          crosshair: true,
          max: end.valueOf(),
          min: start.valueOf(),
          events: {
            afterSetExtremes: (event) => {
              setTimeRange({
                start: new Date(event.min),
                end: new Date(event.max),
              });
            },
          },
          plotBands,
          // When there's no data, set minRange to a full day
          ...(lineSeries.length === 0
            ? { minRange: hoursToMilliseconds(24) }
            : undefined),
        },
        yAxis,
        series: [...polygonSeries, ...lineSeries],
      };
    },
    [
      end,
      lineSeries,
      measurementsQuery.called,
      measurementsQuery.loading,
      plotBands,
      polygonSeries,
      signals,
      start,
      yAxis,
    ]
  );
  const handleChartInitialization = useCallback((instance) => {
    setChart(instance);
  }, []);
  const handleToggleSeries = useCallback(
    (selectedSignals: MeasurementTypeConfig[]) => {
      if (chart) {
        for (const series of chart.series) {
          const isSelected = selectedSignals.some(
            ({ type }) => type === series.userOptions.id
          );
          series.setState(
            selectedSignals.length === 0
              ? 'normal'
              : isSelected
                ? 'hover'
                : 'inactive'
          );
        }
      }
    },
    [chart]
  );

  useChartHints({ chart, showResetZoom });

  return (
    <>
      <div className="h-full flex gap-2">
        <Sidebar
          className="min-w-fit"
          signalIds={signalIds}
          groups={groups}
          updateSignalIds={updateSignalIds}
        />

        <div className="relative w-full min-w-0 flex flex-col gap-4">
          <Toolbar
            chart={chart!}
            signals={signals}
            disabledViewType={disabledViewType || measurementsQuery.loading}
            showResetZoom={showResetZoom && !measurementsQuery.loading}
            onToggleSeries={handleToggleSeries}
          />

          <Chart
            className="flex-auto"
            role="figure"
            aria-label={`line chart for ${signals[0]!.type}`}
            key={`${signals[0]!.type}-${start.valueOf()}-${end.valueOf()}-${viewType}`}
            options={options}
            loading={measurementsQuery.loading}
            onInitialization={handleChartInitialization}
          />
        </div>
      </div>

      {chart?.hasLoaded && (
        <Annotations
          chart={chart}
          data={measurementsQuery.data}
          signals={signals}
          timeRange={timeRange}
          zone={zone}
          start={start}
          end={end}
          isLoading={measurementsQuery.loading}
        />
      )}

      <TimelineRange mode="range" dates={timelineDates} brush={timeRange} />
    </>
  );
};

export const NewLineChart = () => {
  const { getRangeStartTime, getRangeEndTime } = useZoneDetailsPageURL();
  const { viewType, setViewType } = useLineChartURL();
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const forceAverage =
    rangeStartTime && rangeEndTime
      ? exceedsMaxAllowedWindow(
          rangeStartTime,
          Math.min(rangeEndTime, currentTimeInCurrentZone.valueOf())
        )
      : false;

  useEffect(() => {
    if (forceAverage && viewType !== AVERAGE) {
      setViewType(AVERAGE);
    }
  }, [forceAverage, setViewType, viewType]);

  if (isNil(currentZone) || isNil(rangeStartTime) || isNil(rangeEndTime)) {
    return null;
  }

  return (
    <LineChart
      zone={currentZone}
      startTime={rangeStartTime}
      endTime={rangeEndTime}
      disabledViewType={forceAverage}
    />
  );
};
