import { useSignalsQuery } from 'api/signals';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import { useCallback, useEffect, useMemo } from 'react';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { usePrevious } from './usePrevious';

const { Environmental, Labels, Others } = EMeasurementGroup;

export function useSignals({
  rawSignalIds,
  zoneUid,
}: Optional<{ rawSignalIds?: string[]; zoneUid?: string }> = {}) {
  const { allMeasurementTypeFilters, getMeasurementType, mapSignalToType } =
    useTypeConfig();
  const permissions = usePermissions();
  const { rangeEndTime, rangeStartTime } = useZoneDetailsPageURL();
  const { currentZone } = useCurrentZone();
  const { signalIds, viewType: aggregation, setSignalIds } = useLineChartURL();
  const previousAggregation = usePrevious(aggregation);
  const {
    data: dynamicSignals = [],
    isFetching,
    isFetched,
  } = useSignalsQuery({
    enabled: permissions.canViewDynamicSignals,
    zoneUid: zoneUid ?? currentZone?.uid,
    start: rangeStartTime,
    end: rangeEndTime,
  });
  const environmentals = useMemo(
    () =>
      allMeasurementTypeFilters.filter((type) => type.group === Environmental),
    [allMeasurementTypeFilters]
  );
  const labels = useMemo(
    () => permissions.labels.map(getMeasurementType),
    [getMeasurementType, permissions.labels]
  );
  const others = useMemo(
    () => dynamicSignals.map(mapSignalToType) as MeasurementTypeConfig[],
    [dynamicSignals, mapSignalToType]
  );
  const othersByAggregation = useMemo(
    () =>
      dynamicSignals
        .filter(
          ({ measurementInformation }) =>
            measurementInformation.aggregation === aggregation.toLowerCase()
        )
        .map(mapSignalToType) as MeasurementTypeConfig[],
    [aggregation, dynamicSignals, mapSignalToType]
  );
  const groups = useMemo(
    () =>
      (
        [
          [Environmental, environmentals],
          [Labels, labels],
          [Others, othersByAggregation],
        ] as [EMeasurementGroup, MeasurementTypeConfig[]][]
      ).filter(([_, types]) => types.length > 0) as [
        EMeasurementGroup,
        MeasurementTypeConfig[],
      ][],
    [environmentals, labels, othersByAggregation]
  );
  const allSignals = useMemo(
    () => [...environmentals, ...labels, ...others],
    [environmentals, labels, others]
  );
  const groupedSignals = useMemo(
    () => groups.flatMap(([_, types]) => types),
    [groups]
  );
  const signals = useMemo(() => {
    let currentSignals = groupedSignals.filter(({ type }) =>
      signalIds.includes(type)
    );

    if (previousAggregation !== aggregation && signalIds.length > 0) {
      currentSignals = othersByAggregation.filter(({ type }) =>
        signalIds.some(
          (signalId) => type.endsWith(signalId) || signalId.endsWith(type)
        )
      );
    }

    return currentSignals.length > 0
      ? currentSignals
      : groupedSignals.slice(0, 1);
  }, [
    aggregation,
    groupedSignals,
    othersByAggregation,
    previousAggregation,
    signalIds,
  ]);
  const getSignals = useCallback(
    (ids: string[]): MeasurementTypeConfig[] => {
      return ids.map(
        (id) =>
          getMeasurementType(id) ?? allSignals.find(({ type }) => type === id)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSignals]
  );
  const rawSignals = useMemo(() => {
    if (rawSignalIds) {
      return getSignals(rawSignalIds).filter(Boolean);
    }
    return [];
  }, [getSignals, rawSignalIds]);
  const updateSignalIds = useCallback(
    (ids: typeof signalIds) => {
      setSignalIds(ids, { replace: true });
    },
    [setSignalIds]
  );

  useEffect(() => {
    const selectedSignalIds = signals.map(({ type }) => type);
    if (
      signalIds.sort().join() !== selectedSignalIds.sort().join() &&
      !isFetching &&
      isFetched
    ) {
      updateSignalIds(selectedSignalIds);
    }
  }, [isFetched, isFetching, signalIds, signals, updateSignalIds]);

  return {
    allSignals,
    getSignals,
    groups,
    rawSignals,
    signals,
    signalIds,
    updateSignalIds,
  };
}
