import { Button } from 'components/common/Button/Button';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { Wrap } from 'components/common/Wrap/Wrap';
import { useCallback, useState } from 'react';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

export const SeriesToggle = ({
  signals,
  onToggleSeries,
}: {
  signals: MeasurementTypeConfig[];
  onToggleSeries: (signals: MeasurementTypeConfig[]) => void;
}) => {
  const [selectedSignals, setSelectedSignals] = useState<
    MeasurementTypeConfig[]
  >([]);
  const canHover = window?.matchMedia?.('(hover: hover)').matches;
  const handleClick = useCallback(
    (signal: MeasurementTypeConfig) => () => {
      const newSelectedSignals = selectedSignals.some(
        ({ type }) => type === signal.type
      )
        ? selectedSignals.filter(({ type }) => type !== signal.type)
        : [...selectedSignals, signal];

      setSelectedSignals(newSelectedSignals);

      onToggleSeries(newSelectedSignals);
    },
    [onToggleSeries, selectedSignals]
  );

  return (
    <Wrap
      condition={canHover}
      with={(children) => (
        <HorizontallyScrollable>{children}</HorizontallyScrollable>
      )}
    >
      <div
        className={cn(
          'flex gap-2 overflow-x-auto no-scrollbar p-1',
          canHover && 'w-fit'
        )}
      >
        {signals.map((signal) => (
          <Button
            key={signal.type}
            variant="secondary"
            className="min-w-max text-xs font-normal"
            selected={selectedSignals.some(({ type }) => type === signal.type)}
            leadingIcon={
              <div className={cn('w-3 h-3 rounded-full', signal.style?.html)} />
            }
            onClick={handleClick(signal)}
          >
            {`${signal.label}${signal.subgroup ? ` (${signal.subgroup}) ` : ''}`}
          </Button>
        ))}
      </div>
    </Wrap>
  );
};
