import { utcToZonedTime } from 'date-fns-tz';
import { useGetDailyHealthLabelsByZoneIdTypeCodeQuery } from 'graphql/generated/react_apollo';
import { useMemo } from 'react';
import {
  GetLineChartDataInput,
  MeasurementTypeConfig,
  SignalMeasurements,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';

export const useGetDailyHealthLabelsByZoneIdTypeCode = ({
  parameters: { zoneId, zoneTimeZone, start, end, signals },
  skip,
}: GetLineChartDataInput) => {
  const typeCodes = signals.map(({ statisticsKeyV2 }) => statisticsKeyV2);

  const { data: rawData, ...result } =
    useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
      skip: skip || typeCodes.length === 0,
      variables: {
        zoneId: zoneId,
        start: start,
        end: end,
        typeCodes: typeCodes,
      },
    });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement
        .filter(({ type }) => type?.code === signal.statisticsKeyV2)
        .map<
          [number, number]
        >(({ time, data }) => [utcToZonedTime(time, zoneTimeZone).valueOf(), Number(data[signal.statisticsKey]) * 100]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of signals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, signals, zoneTimeZone]);

  return { data, ...result };
};
