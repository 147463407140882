import { useAuth } from 'contexts/AuthProvider';
import Cookies from 'js-cookie';
import memoize from 'lodash.memoize';
import { useCallback, useMemo } from 'react';
import { FEATURE_ENABLED } from 'shared/constants/general';
import { EMeasurementTypes } from 'shared/interfaces/measurement';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { BetaFlag } from 'shared/models/beta-flag';
import { DebugFlag } from 'shared/models/debug-flag';
import { Permission } from 'shared/models/permission';
import { useCurrentZone } from './useCurrentZone';
import { useFeatureFlags } from './useFeatureFlags';
import { useUserPermissions } from './useUserPermissions';

export const usePermissions = () => {
  const { getBetaFlagValue, getDebugFlagValue } = useFeatureFlags();
  const { permissionContainer } = useUserPermissions();
  const { currentZone } = useCurrentZone();
  const { currentlySelectedOrganization } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPermissionValue = useCallback(
    memoize((permission: Permission): boolean => {
      if (!permissionContainer) {
        return false;
      }

      if (
        currentZone &&
        permissionContainer.hasZoneLocationOrOrganizationPermission(
          permission,
          {
            locationId: currentZone.locationId,
            organizationCode: currentZone.organizationCode,
            zoneUid: currentZone.uid,
          }
        )
      ) {
        return true;
      }

      if (
        currentlySelectedOrganization &&
        permissionContainer.hasOrganizationPermission(
          permission,
          currentlySelectedOrganization.code
        )
      ) {
        return true;
      }

      // TODO: Discuss if we can remove this. It feels wrong to be able to add permissions as a cookie !
      const cookiePermission = Cookies.get(permission) === FEATURE_ENABLED;

      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (cookiePermission) {
        return true;
      }

      return false;
    }),
    [permissionContainer, currentZone]
  );

  const getNeatleafPermission = useCallback(
    (permission: Permission) => {
      if (!permissionContainer) {
        return false;
      }
      return permissionContainer.hasOrganizationPermission(
        permission,
        NEATLEAF_ORGANIZATION_CODE
      );
    },
    [permissionContainer]
  );

  const canEditGrowthCycles = getPermissionValue(Permission.GrowthCycleUpdate);
  const canEditCriticalEnvSettings = getPermissionValue(
    Permission.LocationUpdate
  );
  const canEditCultivarAssignment = getPermissionValue(
    Permission.GrowthCycleUpdate
  );
  const canEditUsers = getPermissionValue(Permission.UserUpdate);
  const canAccessUserSettings = getPermissionValue(Permission.UserSettingsView);
  const canViewNdvi = getBetaFlagValue(BetaFlag.NDVI);
  const canViewImageLabels = getPermissionValue(Permission.ImageLabelView);
  const canViewHomepage = getPermissionValue(Permission.HomepageView);
  const canViewHeatMap = getPermissionValue(Permission.HeatMapView);
  const canViewLineChart = getPermissionValue(Permission.LineChartView);
  const canDebugImageFeed = getDebugFlagValue(DebugFlag.IMAGE_FEED);
  const canDebugImageSizeIndex = getDebugFlagValue(DebugFlag.IMAGE_SIZE_INDEX);
  const canViewSoilSensorData = getBetaFlagValue(BetaFlag.SOIL_SENSOR_DATA);

  const canViewDynamicSignals = getBetaFlagValue(BetaFlag.DYNAMIC_SIGNALS);

  const discussions = useMemo(
    () => ({
      canView: getPermissionValue(Permission.DiscussionView),
      canCreate: getPermissionValue(Permission.DiscussionCreate),
      canUpdate: getPermissionValue(Permission.DiscussionUpdate),
      canDelete: getPermissionValue(Permission.DiscussionDelete),
    }),
    [getPermissionValue]
  );

  const comments = useMemo(
    () => ({
      canView: getPermissionValue(Permission.CommentThreadView),
      canCreate: getPermissionValue(Permission.CommentThreadCreate),
      canUpdate: getPermissionValue(Permission.CommentThreadUpdate),
      canDelete: getPermissionValue(Permission.CommentThreadDelete),
    }),
    [getPermissionValue]
  );

  const insights = useMemo(
    () => ({
      canCreate: getNeatleafPermission(Permission.InsightCreate),
      canViewDraft: getNeatleafPermission(Permission.InsightDraftView),
      canPublish: getNeatleafPermission(Permission.InsightPublish),
    }),
    [getNeatleafPermission]
  );

  const labels = useMemo(
    () =>
      [
        [EMeasurementTypes.AbnormalShapeFolding, true],
        [
          EMeasurementTypes.AbnormalShapeWilted,
          getBetaFlagValue(BetaFlag.VIEW_LINE_CHART_ABNORMAL_SHAPE_WILTED),
        ],
        [EMeasurementTypes.NecrosisGeneral, true],
        [EMeasurementTypes.ObjectBud, true],
        [EMeasurementTypes.OtherPowder, true],
        [EMeasurementTypes.YellowingEdges, true],
        [EMeasurementTypes.YellowingGeneral, true],
      ]
        .filter(([_, visible]) => visible)
        .map(([type]) => type!) as EMeasurementTypes[],
    [getBetaFlagValue]
  );

  return {
    canAccessUserSettings,
    canDebugImageFeed,
    canDebugImageSizeIndex,
    canEditGrowthCycles,
    canEditUsers,
    canEditCultivarAssignment,
    canEditCriticalEnvSettings,
    canViewDynamicSignals,
    canViewHeatMap,
    canViewHomepage,
    canViewImageLabels,
    canViewLineChart,
    canViewNdvi,
    canViewSoilSensorData,
    discussions,
    comments,
    insights,
    labels,
  };
};
