import { Button } from 'components/common/Button/Button';
import { CircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useMemo } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import {
  EMeasurementTypes,
  MeasurementTypeConfig,
  THealthMeasurement,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { EnvironmentalHealthChart } from './EnvironmentalHealthChart';

interface EnvironmentalHealthProps {
  /** Allows styling of this component root element */
  className?: string;
  /** The measurement data to display in the chart */
  healthData: THealthMeasurement[];
  /** The provided time range */
  timeRange: TTimeRange;
  /** Whether the loading state is enabled. */
  loading?: boolean;
  /** Called when the user clicks one of the measurements chip pills */
  onClickMeasurementType: (measurementType: EMeasurementTypes) => void;
}

/**
 * Displays a chart with the overview on the room's environmental health.
 * Allows navigation to a specific measurement line chart
 * */
export const EnvironmentalHealth = ({
  className,
  healthData,
  timeRange,
  loading,
  onClickMeasurementType,
}: EnvironmentalHealthProps) => {
  const { getMeasurementType } = useTypeConfig();
  const availableTypes: MeasurementTypeConfig[] = useMemo(() => {
    return Array.from(new Set(healthData.map((data) => data.type)))
      .sort()
      .map((type) => getMeasurementType(type));
  }, [getMeasurementType, healthData]);

  return (
    <div className={cn('min-w-full flex flex-col gap-3 @container', className)}>
      <p className="text-xs font-bold text-neutral-600" id="env-health-title">
        Environmental health (Past 3 days)
      </p>

      <div className="grid grid-cols-1 grid-rows-1 items-center justify-items-center">
        <EnvironmentalHealthChart
          className="[grid-area:1/1]"
          data={healthData}
          timeRange={timeRange}
          aria-labelledby="env-health-title"
          getMeasurementType={getMeasurementType}
        />
        {loading && <CircularProgress className="[grid-area:1/1]" />}
      </div>

      <div className="min-h-4 flex gap-2 @4xl:justify-center overflow-x-auto no-scrollbar">
        {availableTypes.map(({ type, label, style }) => (
          <Button
            key={type}
            variant="secondary"
            leadingIcon={
              <div className={cn('w-3 h-3 rounded-full', style?.html)} />
            }
            className="min-w-max text-xs font-normal"
            onClick={() => onClickMeasurementType(type)}
          >
            {label}
          </Button>
        ))}

        {availableTypes.length === 0 && !loading && (
          <p className="text-xs text-red-500" role="alert">
            There is no data for the past 3 days.
          </p>
        )}
      </div>
    </div>
  );
};
